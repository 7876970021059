import { Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  calendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarButton: {
    backgroundColor: theme.palette.common.white
  },
  form: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    paddingLeft: 10
  },
  notificationBtn: {
    marginRight: 10
  },
  checkbox: {
    marginLeft: 20
  },
  saveButton: {
    minWidth: 'auto',
    marginTop: 15
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  orderIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({ className, isSystem, ...rest }) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const open = Boolean(selectEdge);

  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(date);

      if (moment(date).isAfter(endDate)) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);

      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
      }
    }

    setSelectEdge(null);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="space-between">
        <Grid item lg={8} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Home
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            Dashboard
          </Typography>
        </Grid>
        {!isSystem && (
          <Grid className={classes.calendar} item lg={3} xs={12}>
            <Button
              color="primary"
              component={RouterLink}
              to="/orders/create"
              variant="outlined"
            >
              <AddIcon className={classes.orderIcon} />
              New Order
            </Button>
          </Grid>
        )}
      </Grid>
      <DatePicker
        maxDate={moment()}
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
      />
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  isSystem: PropTypes.bool
};

Header.defaultProps = {};

export default Header;
