import React, { useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import { makeStyles } from '@material-ui/styles';
import {
  Container,
  Card,
  CardContent,
  colors,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import Toolbar from './Toolbar';
import PropType from 'prop-types';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .fc-unthemed td': {
      borderColor: theme.palette.divider
    },
    '& .fc-widget-header': {
      backgroundColor: colors.grey[50]
    },
    '& .fc-axis': {
      ...theme.typography.body2
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6
    },
    '& .fc th': {
      borderColor: theme.palette.divider
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle2,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: colors.grey[50]
    },
    '& .fc-day-top': {
      ...theme.typography.body2
    },
    '& .fc-highlight': {
      backgroundColor: colors.blueGrey[50]
    },
    '& .fc-event': {
      backgroundColor: theme.palette.calendar.eventBackground,
      color: theme.palette.primary.contrastText,
      borderWidth: 2,
      borderColor: theme.palette.calendar.eventBackgroundBorder,
      opacity: 0.9,
      '& .fc-time': {
        ...theme.typography.h6,
        color: 'inherit'
      },
      '& .fc-title': {
        ...theme.typography.body1,
        color: 'inherit'
      }
    },
    '& .fc-unthemed td.fc-today': {
      background: theme.palette.calendar.todayDate
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1
    }, 
    '& .fc-content': {
      whiteSpace: 'pre-wrap'
    }
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

function Calendar({
  events,
  onStartDateUpdated = date => {},
  onEndDateUpdated = date => {}
}) {
  const classes = useStyles();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState(mobileDevice ? 'listWeek' : 'dayGridMonth');
  const [date, setDate] = useState(moment().toDate());

  /**
   * bubbles up start and end range when the month view changes.
   *
   * Months are the biggest view so its always safe to fetch by the start and end of the month around the time, padding 14 days
   *
   * @param {Date} date
   */
  const changeCalendarMonthView = useCallback(
    anyMonthDate => {
      onEndDateUpdated(addDays(endOfMonth(anyMonthDate), 7));
      onStartDateUpdated(subDays(startOfMonth(anyMonthDate), 7));
    },
    [onEndDateUpdated, onStartDateUpdated]
  );

  const handleDateToday = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.today();
    setDate(calendarApi.getDate());
  };

  const handleViewChange = newView => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(newView);
    setView(newView);
  };

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setDate(calendarApi.getDate());
    changeCalendarMonthView(calendarApi.getDate());
  };

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setDate(calendarApi.getDate());
    changeCalendarMonthView(calendarApi.getDate());
  };

  const handleCalendarChange = useCallback(() => {
    const calendarApi = calendarRef.current.getApi();
    let startDate = new Date(calendarApi.getDate());
    startDate.setMonth(startDate.getMonth() - 1);
    changeCalendarMonthView(calendarApi.getDate());
  }, [changeCalendarMonthView]);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const newView = mobileDevice ? 'listWeek' : 'dayGridMonth';
    handleCalendarChange();
    calendarApi.changeView(newView);
    setView(newView);
  }, [mobileDevice, handleCalendarChange]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <Toolbar
        date={date}
        onDateNext={handleDateNext}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        // onEventAdd={handleEventNew}
        onViewChange={handleViewChange}
        view={view}
      />
      <Card className={classes.card}>
        <CardContent>
          <FullCalendar
            allDayMaintainDuration
            defaultDate={date}
            defaultView={view}
            // droppable
            // editable
            // eventClick={handleEventClick}
            // eventResizableFromStart
            events={events}
            header={false}
            height={800}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              timelinePlugin
            ]}
            ref={calendarRef}
            rerenderDelay={10}
            // selectable
            weekends
          />
        </CardContent>
      </Card>
      {/* <Modal onClose={handleModalClose} open={eventModal.open}>
        <AddEditEvent
          event={eventModal.event}
          onAdd={handleEventAdd}
          onCancel={handleModalClose}
          onDelete={handleEventDelete}
          onEdit={handleEventEdit}
        />
      </Modal> */}
    </Container>
  );
}

Calendar.propTypes = {
  events: PropType.array,
  onStartDateUpdated: PropType.string,
  onEndDateUpdated: PropType.string
};

export default Calendar;
