import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { SnackbarProvider } from 'notistack';
import firebase from 'src/firebase';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';

const history = createBrowserHistory();
const store = configureStore();

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
    enableClaims: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
};

function App() {
  const [direction] = useState('ltr');

  return (
    <StoreProvider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
          <StylesProvider direction={direction}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider>
                <Router history={history}>
                  <ScrollReset />
                  {renderRoutes(routes)}
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </StoreProvider>
  );
}

export default App;
