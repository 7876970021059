import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import OrderAssignListItem from './OrderAssignListItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  avatar: {
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  },
  selectUnassigned: {
    color: 'red'
  }
}));

function OrderAssignList({ jobs }) {
  const classes = useStyles();
  return (
    <List className={classes.List} disablePadding>
      {jobs.map(job => (
        <OrderAssignListItem job={job} key={job.id} />
      ))}
    </List>
  );
}

OrderAssignList.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.array
};

export default OrderAssignList;
