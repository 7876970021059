import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { FormControl, TextField, Button, Grid } from '@material-ui/core';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import { Formik } from 'formik';
import PropType from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  filterButton: {
    marginLeft: 'auto'
  }
}));

function CalendarFilter({ filterCalendar, shops, techs }) {
  const classes = useStyles();

  return (
    <div>
      <Formik
        initialValues={{
          shop: null,
          tech: null
        }}
        onSubmit={values => {
          filterCalendar(values);
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Shops"
                    margin="dense"
                    name="shop"
                    value={formik.values.shop}
                    onChange={formik.handleChange}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                  >
                    <option value="null">All</option>
                    {shops &&
                      Object.keys(shops).map((key, index) => (
                        <option key={key} value={key}>
                          {shops[key].name}
                        </option>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Tech"
                    margin="dense"
                    name="tech"
                    value={formik.values.tech}
                    onChange={formik.handleChange}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                  >
                    <option value="null">All</option>
                    {techs &&
                      techs.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  className={classes.filterButton}
                  color="primary"
                  onClick={() => formik.submitForm()}
                  size="small"
                  variant="outlined"
                >
                  <SearchIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
}

CalendarFilter.propTypes = {
  filterCalendar: PropType.object,
  shops: PropType.object,
  techs: PropType.array
};

export default CalendarFilter;
