import { Button, colors, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';

const statusColors = {
  active: colors.blue[600],
  pending: colors.orange[600],
  complete: colors.green[600]
};

function OrderTable({
  interactable = true,
  showFleetJobs = false,
  orders,
  title = 'Orders',
  filter,
  onFilterChange
}) {
  const techMap = useSelector(state => state.firestore.data.techs);

  const formatted = useMemo(
    () =>
      orders.map((order, index) => {
        let columnValues = [
          order.type,
          moment(order.date).valueOf(),
          order.status,
          order.assignee,
          <Button
            key={index}
            to={`/orders/${showFleetJobs ? 'fleet/' : ''}${order.id}`}
            component={RouterLink}
            color="primary"
            size="small"
            variant="outlined"
          >
            View
          </Button>
        ];
        if (!showFleetJobs) {
          columnValues.splice(3, 0, order.shopDoc);
        }
        return columnValues;
      }),
    [orders, showFleetJobs]
  );

  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const columns = useMemo(() => {
    const cols = [
      {
        label: 'Type',
        name: 'type',
        options: {
          display: !isSm,
          customBodyRender: value => {
            return (
              <div style={{ display: 'flex' }}>
                <Tooltip title={value} placement="right">
                  {value === 'Repair' ? (
                    <BuildIcon htmlColor={colors.grey[500]} />
                  ) : (
                    <AssignmentIcon htmlColor={colors.grey[500]} />
                  )}
                </Tooltip>
              </div>
            );
          }
        }
      },
      {
        label: 'Date',
        name: 'date',
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: value => {
            return moment(value).format('MM/DD/YYYY HH:mm');
          }
        }
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filterList: filter && filter !== 'all' ? [filter] : [],
          customBodyRender: value => {
            return (
              <Label color={statusColors[value]} variant="outlined">
                {value}
              </Label>
            );
          }
        }
      },
      ...(showFleetJobs
        ? []
        : [
            {
              label: 'Location',
              name: 'shopDoc',
              options: {
                display: !isSm,
                customBodyRender: value => {
                  return value?.name;
                }
              }
            }
          ]),
      {
        label: 'Assignee',
        name: 'assignee',
        options: {
          display: !isSm,
          customBodyRender: value => {
            if (!value || !techMap?.[value]) {
              return '';
            }
            return techMap[value].name;
          }
        }
      },
      {
        label: 'Actions',
        name: 'actions',
        options: {
          filter: false
        }
      }
    ];
    return showFleetJobs ? cols.filter(col => col.name !== 'shop') : cols;
  }, [showFleetJobs, filter, techMap, isSm]);

  return (
    <MUIDataTable
      title={title}
      options={{
        elevation: interactable ? 1 : 0,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        resizableColumns: false,
        pagination: interactable,
        filter: interactable,
        search: interactable,
        onFilterChange: onFilterChange
      }}
      data={formatted}
      columns={columns}
    />
  );
}

OrderTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
  interactable: PropTypes.bool,
  title: PropTypes.string,
  filter: PropTypes.string,
  onFilterChange: PropTypes.func,
  showFleetJobs: PropTypes.bool
};

OrderTable.defaultProps = {
  orders: []
};

export default OrderTable;
