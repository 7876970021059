import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  alert: {
    marginBottom: theme.spacing(3)
  },

  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

function RegionGlobal({ className, ...rest }) {
  const classes = useStyles();
  const {
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue
  } = useFormikContext();

  const options = [
    {
      value: true,
      name: 'isGlobal',
      title: 'Global'
    },
    {
      value: false,
      name: 'isLocal',
      title: 'Local'
    }
  ];

  const getError = key =>
    (touched.address || {})[key] && (errors.address || {})[key];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Region global" />
      <CardContent className={classes.cardContent}>
        <div className={classes.formGroup}>
          <FormGroup>
            {options.map(option => (
              <FormControlLabel
                key={option.name}
                control={
                  <Radio
                    error={!!getError('isGlobal')}
                    helperText={getError('isGlobal')}
                    name={option.name}
                    onClick={() => setFieldValue(option.name, option.value)}
                    checked={option.value === values.isGlobal}
                    defaultChecked={option.value === values.isGlobal}
                    onBlur={handleBlur}
                    value={values.isGlobal}
                  />
                }
                label={option.title}
              />
            ))}
          </FormGroup>
        </div>
      </CardContent>
    </Card>
  );
}

RegionGlobal.propTypes = {
  className: PropTypes.string
};

export default RegionGlobal;
