import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import TechList from 'src/components/TechList';

const useStyles = makeStyles(theme => ({
  root: {},
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    padding: theme.spacing(3),
    flexGrow: 1,
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  content: {
    padding: 0
  },
  date: {
    whiteSpace: 'nowrap'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function ActiveTechs({ className, ...rest }) {
  const classes = useStyles();
  const techs = useSelector(state => state.firestore.ordered.techs) || [];
  const availableTechs = techs.filter(tech => tech.status === 'available');

  const sortByUpdatedAt = (techA, techB) =>
    (techB.updatedAt || 0) - (techA.updatedAt || 0);
  const slicedTechs = availableTechs
    .slice()
    .sort(sortByUpdatedAt)
    .slice(0, 5);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Tech Activity" />
      <Divider />
      <div className={classes.statsContainer}>
        <div className={classes.statsItem}>
          <Typography
            align="center"
            component="h4"
            gutterBottom
            variant="overline"
          >
            Registered
          </Typography>
          <Typography align="center" variant="h3">
            {techs.length}
          </Typography>
        </div>
        <Divider />
        <div className={classes.statsItem}>
          <Typography
            align="center"
            component="h4"
            gutterBottom
            variant="overline"
          >
            Available
          </Typography>
          <Typography align="center" variant="h3">
            {availableTechs.length}
          </Typography>
        </div>
      </div>
      <Divider />
      <CardContent className={classes.content}>
        <TechList techs={slicedTechs} showSearch={false} />
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/techs"
          variant="text"
        >
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
}

ActiveTechs.propTypes = {
  className: PropTypes.string
};

export default ActiveTechs;
