import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import sessionReducer from './sessionReducer';
import mapReducer from './mapReducer';
import regionReducer from './regionReducer';
const rootReducer = combineReducers({
  map: mapReducer,
  session: sessionReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  region: regionReducer
});

export default rootReducer;
