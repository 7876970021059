import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  colors
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  active: {
    boxShadow: `inset 4px 0px 0px ${theme.palette.primary.main}`,
    backgroundColor: colors.grey[50]
  },
  avatar: {
    height: 40,
    width: 40
  },
  details: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  availableBadge: {
    backgroundColor: colors.indigo[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.purple[600]
  },
  unavailableBadge: {
    backgroundColor: colors.red[600]
  }
}));

function TechListItem({ active, tech, shop, className, ...rest }) {
  const classes = useStyles();
  const marker = useSelector(state => state.map.techMarkers[tech.id]);
  const [techUpdatedTime, setTechUpdatedTime] = useState(() =>
    tech.updatedAt ? moment(tech.updatedAt).fromNow() : ''
  );

  const handleMouseEnter = () => {
    if (marker) {
      marker.setZIndex(99);
      marker.setAnimation(window.google.maps.Animation.BOUNCE);
    }
  };

  const handleMouseLeave = () => {
    if (marker) {
      marker.setZIndex(null);
      marker.setAnimation(null);
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      setTechUpdatedTime(moment(tech.updatedAt).fromNow());
    }, 30000);
    return () => clearInterval(id);
  }, [tech.updatedAt]);

  return (
    <ListItem
      {...rest}
      button
      className={clsx(
        {
          [classes.active]: active
        },
        className
      )}
      component={RouterLink}
      to={`/techs/${tech.id}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemAvatar>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.availableBadge]: tech.status === 'available',
              [classes.unavailableBadge]: tech.status === 'unavailable'
            })
          }}
          variant="dot"
        >
          <Avatar className={classes.avatar}>{getInitials(tech.name)}</Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={tech.name}
        primaryTypographyProps={{
          noWrap: true,
          variant: 'h6'
        }}
        secondary={(shop || {}).name}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'body1'
        }}
      />
      <div className={classes.details}>
        {tech.updatedAt && (
          <Typography noWrap variant="body2">
            {/* {moment(tech.updatedAt).isSame(moment(), 'day')
            ? moment(tech.updatedAt).format('LT')
            : moment(tech.updatedAt).fromNow()} */}
            {techUpdatedTime}
          </Typography>
        )}
      </div>
    </ListItem>
  );
}

TechListItem.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  tech: PropTypes.object.isRequired
};

export default TechListItem;
