import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import OrderAssignList from 'src/components/Order/OrderAssignList';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    height: 400,
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    },
    overflowY: 'auto'
  },
  chartContainer: {
    padding: theme.spacing(3)
  },
  chart: {
    height: 281
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

function PendingOrderAssign({ className, ...rest }) {
  const classes = useStyles();
  const jobs = useSelector(state => state.firestore.ordered.allJobs) || [];
  const pendingJobs = jobs.filter(job => job.status === 'pending');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Pending Jobs" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <OrderAssignList jobs={pendingJobs} />
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

PendingOrderAssign.propTypes = {
  className: PropTypes.string
};

export default PendingOrderAssign;
