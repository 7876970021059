import * as actionTypes from 'src/actions';

const initialState = {
  region: null
};

const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REGION:
      return {
        ...state,
        region: action.payload.region
      };

    default: {
      return state;
    }
  }
};

export default regionReducer;
