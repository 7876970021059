import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/analytics';
import { useFirestore } from 'react-redux-firebase';

const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;

if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp({
    apiKey:
      process.env.REACT_APP_FIREBASE_API_KEY ??
      'AIzaSyBJG9us-LG8jB36of3efStaNXHyyWrnpdY', // ,
    authDomain:
      process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ??
      'techrepair-44586.firebaseapp.com', //,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'techrepair-44586', // 'solutionworks-23e0f',
    storageBucket:
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ??
      'techrepair-44586.appspot.com', // 'solutionworks-23e0f.appspot.com',
    messagingSenderId:
      process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID ?? '491767278379', // '838954597607',
    appId: process.env.APP_ID ?? '1:491767278379:web:ea675a787088bcf853f36e' // '1:838954597607:web:26ed21c6cbf90a69d57642'
  });
  if (
    window.location.href.includes('http://localhost') &&
    [true, 'true'].includes(process.env.REACT_APP_USE_FIREBASE_EMULATOR)
  ) {
    firebase.auth().useEmulator('http://localhost:5002');
    firebase.functions().useEmulator('localhost', 5001);
    firebase.firestore().useEmulator('localhost', 5000);
  }
}

export default firebase;

export function useAssignFleetJob() {
  const firestore = useFirestore();
  return [
    async (regionId, orderId, oldTechId, newTechId) => {
      newTechId = newTechId === 'Unassigned' ? null : newTechId;
      oldTechId = oldTechId === 'Unassigned' ? null : oldTechId;

      const batch = firestore.batch();
      const jobRef = firestore
        .collection(`/regions/${regionId}/fleetJobs`)
        .doc(orderId);

      if (oldTechId) {
        const oldTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(oldTechId);
        batch.update(oldTechRef, {
          fleetJobs: arrayRemove(orderId)
        });
      }

      if (newTechId) {
        const newTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(newTechId);
        batch.update(newTechRef, {
          fleetJobs: arrayUnion(orderId)
        });
      }

      batch.update(jobRef, {
        assignee: newTechId
      });

      await batch.commit();
    }
  ];
}

export function useAssignJob() {
  const firestore = useFirestore();
  return [
    async (regionId, orderId, oldTechId, newTechId) => {
      newTechId = newTechId === 'Unassigned' ? null : newTechId;
      oldTechId = oldTechId === 'Unassigned' ? null : oldTechId;

      const batch = firestore.batch();
      const jobRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      if (oldTechId) {
        const oldTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(oldTechId);
        batch.update(oldTechRef, {
          fleetJobs: arrayRemove(orderId)
        });
      }

      if (newTechId) {
        const newTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(newTechId);
        batch.update(newTechRef, {
          fleetJobs: arrayUnion(orderId)
        });
      }

      batch.update(jobRef, {
        assignee: newTechId
      });

      await batch.commit();
    }
  ];
}

export function useDeleteOrder(regionId, { onComplete }) {
  const firestore = useFirestore();

  return [
    async orderId => {
      const batch = firestore.batch();
      const orderRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      const snap = await firestore
        .collection(`/regions/${regionId}/techs`)
        .where('jobs', 'array-contains', orderId)
        .get();
      snap.forEach(doc => {
        console.log('removing from doc');
        batch.update(doc.ref, {
          jobs: arrayRemove(orderId)
        });
      });

      batch.delete(orderRef);

      await batch.commit();

      if (onComplete) {
        onComplete();
      }
    }
  ];
}
