import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { createBrowserHistory } from 'history';
// import LoadingScreen from 'components/LoadingScreen'; // change it to your custom component

const locationHelper = locationHelperBuilder({});
const browserHistory = createBrowserHistory();

export const UserIsAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAuthenticated',
  //   AuthenticatingComponent: LoadingScreen,
  allowRedirectBack: true,
  redirectPath: ({ firebase: { profile } }, ownProps) => {
    if (profile.isLoaded && !profile.isEmpty && !profile.token.claims.admin) {
      return '/errors/error-401';
    }
    if (profile.isLoaded && !profile.isEmpty && profile.token.claims.otp) {
      return '/errors/error-otp';
    }
    return locationHelper.getRedirectQueryParam(ownProps) || `/auth/login`;
  },
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true || !profile.isLoaded,
  authenticatedSelector: ({ firebase: { auth, profile } }) => {
    return (
      auth.isLoaded &&
      !auth.isEmpty &&
      profile.isLoaded &&
      profile.token.claims.admin &&
      !profile.token.claims.otp
    );
  },
  redirectAction: newLoc => dispatch => {
    browserHistory.replace(newLoc); // or routerActions.replace
    dispatch({ type: 'UNAUTHED_REDIRECT' });
  }
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsNotAuthenticated',
  //   AuthenticatingComponent: LoadingScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/techs',
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty,
  redirectAction: newLoc => dispatch => {
    browserHistory.replace(newLoc); // or routerActions.replace
    dispatch({ type: 'UNAUTHED_REDIRECT' });
  }
});
