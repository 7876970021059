import * as actionTypes from 'src/actions';

const initialState = {
  techMarkers: {
  }
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MARKER: {
      return {
        ...state,
        techMarkers: {
          ...state.techMarkers,
          [action.id]: action.marker
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default mapReducer;
