import { format, subDays } from 'date-fns';
import { useFirestoreConnect } from 'react-redux-firebase';

const RegionWrapper = ({ region, children }) => {
  useFirestoreConnect([
    {
      collection: `/regions/${region}/techs`,
      storeAs: 'techs',
      // orderBy: ['available'],
      where: ['available', '==', true]
    },
    {
      collection: `/regions/${region}/techs`,
      storeAs: 'allTechs'
      // orderBy: ['available'],
    },
    {
      collection: `/techs`,
      storeAs: 'globalTechs',
      orderBy: ['name', 'asc']
    },
    {
      collection: `/regions/${region}/shops`,
      storeAs: 'shops',
      orderBy: ['name', 'asc']
    },
    {
      collection: `/regions/${region}/jobs`,
      storeAs: 'jobs',
      where: [
        ['date', '>=', format(subDays(new Date(), 30), 'yyyy-MM-dd HH:mm')]
      ]
    },
    { collection: `/regions/${region}/fleetJobs`, storeAs: 'fleetJobs' },
    {
      collection: `/allJobs`,
      storeAs: 'allJobs',
      where: [
        ['regionId', '==', region],
        ['date', '>=', format(subDays(new Date(), 30), 'yyyy-MM-dd HH:mm')]
      ]
    }
  ]);

  return children;
};

export default RegionWrapper;
