import {
  Avatar,
  CircularProgress,
  colors,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useAssignJob } from 'src/firebase';
import { useAssignFleetJob } from 'src/firebase';
import { RegionContext } from 'src/providers/RegionProvider';
import haversineDistance from 'src/utils/haversineDistance';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  avatar: {
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  selectProgress: {
    position: 'absolute',
    top: '50%',
    right: 24,
    marginLeft: -6
  }
}));

const addressToString = address =>
  `${address.street}, ${address.city}, ${address.state} ${address.state}`;

function OrderAssignListItem({ className, job, ...rest }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [assignJob] = useAssignJob();
  const [assignFleetJob] = useAssignFleetJob();
  const { enqueueSnackbar } = useSnackbar();

  const techs = useSelector(state => state.firestore.ordered.techs) || [];
  const shopMap = useSelector(state => state.firestore.data.shops) || {};

  const availableTechs = techs.filter(tech => tech.status === 'available');

  const { region } = useContext(RegionContext);
  const distanceBetween = (tech, job) => {
    if (
      !tech ||
      !job ||
      !tech.location ||
      (tech.location?.toLowerCase() === 'none' && (!tech.lat || !tech.lng)) ||
      !job.location
    ) {
      return Number.MAX_VALUE;
    }

    const techShop = shopMap[tech.location];

    // eslint-disable-next-line no-use-before-define
    return haversineDistance(
      { lat: tech.lat ?? techShop?.lat, lng: tech.lng ?? techShop?.lng },
      { lat: job.location.latitude, lng: job.location.longitude }
    ).toFixed(1);
  };

  const getSortedTechs = job => {
    return (availableTechs || [])
      .map(tech => {
        return { ...tech, distance: distanceBetween(tech, job) };
      })
      .sort((a, b) => a.distance - b.distance);
  };

  const handleChange = (evt, job) => {
    setLoading(true);
    const id = evt.target.value;
    const assign = job.jobType === 'shop' ? assignJob : assignFleetJob;
    assign(region, job.id, job.assignee, id)
      .then(() => {
        enqueueSnackbar(
          `Job ${id === 'Unassigned' ? 'unassigned' : 'assigned'}`,
          { variant: 'success' }
        );
      })
      .catch(err => {
        enqueueSnackbar('Error assigning job: ' + err.message, {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <ListItem divider={true} key={job.id}>
      <ListItemAvatar>
        <IconButton
          size="small"
          component={RouterLink}
          to={`/orders/${job.id}`}
        >
          <Avatar
            className={classes.avatar}
            size="small"
            style={{ backgroundColor: colors.grey[500] }}
          >
            {job.type === 'Repair' ? <BuildIcon /> : <AssignmentIcon />}
          </Avatar>
        </IconButton>
      </ListItemAvatar>

      <ListItemText
        primary={
          shopMap[job.shop]?.name ?? `Fleet: ${addressToString(job.address)}`
        }
        secondary={job.date}
      />
      <div className={classes.wrapper}>
        <TextField
          label="Assignee"
          name="assignee"
          fullWidth
          className={classes.select}
          onChange={evt => handleChange(evt, job)}
          select
          SelectProps={{ native: true }}
          disabled={loading}
          placeholder="Unassigned"
          value={
            (techs.find(tech => job.assignee === tech.id) || {}).id ||
            'Unassigned'
          }
        >
          <option value="Unassigned">Unassigned</option>
          {getSortedTechs(job).map(tech => (
            <option key={tech.id} value={tech.id}>
              {`${tech.name} ${
                tech.distance !== Number.MAX_VALUE ? `(${tech.distance} m)` : ''
              }`}
            </option>
          ))}
        </TextField>
        {loading && (
          <CircularProgress size={16} className={classes.selectProgress} />
        )}
      </div>
    </ListItem>
  );
}

OrderAssignListItem.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.array,
  job: PropTypes.object
};

export default OrderAssignListItem;
