import { Divider, IconButton, Input, List, Toolbar } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TechListItem from './TechListItem';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  searchInput: {
    flexGrow: 1
  }
}));

// eslint-disable-next-line react/prop-types
function TechList({ techs, showSearch = true, className, ...rest }) {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const shopMap = useSelector(state => state.firestore.data.shops);
  const filteredTechs = techs.filter(tech =>
    tech.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {showSearch && (
        <>
          <Toolbar>
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={evt => setSearch(evt.target.value || '')}
              placeholder="Search techs"
            />
            {search && (
              <IconButton edge="end" size="small" onClick={() => setSearch('')}>
                <ClearIcon />
              </IconButton>
            )}
          </Toolbar>
          <Divider />
        </>
      )}
      <List disablePadding>
        {filteredTechs.map(tech => (
          <TechListItem
            tech={tech}
            shop={(shopMap || {})[tech.location]}
            // divider={i < filteredTechs.length - 1}
            divider={true}
            key={tech.id}
          />
        ))}
      </List>
    </div>
  );
}

TechList.propTypes = {
  className: PropTypes.string,
  techs: PropTypes.array.isRequired
};

export default TechList;
